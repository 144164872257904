import React, { Component } from 'react';
import { Elements, StripeProvider, CardElement } from 'react-stripe-elements';
import { loadStripe } from '@stripe/stripe-js';
import Header from './components/header.js';
import Footer from './components/footer.js';
import Donate from './components/donate.js';
import DonationOptions from './components/donation-options.js';
import LearnMoreButton from './components/learn-more.js';
import SocialComponent from './components/social-component.js';
import Checkout from './pages/page-donate.js';
import { Link, withRouter } from 'react-router-dom';
import heroImage from './images/hero_image.png';
import './App.scss';
const data = require('./data/data.json');

class App extends Component {
	renderBody = () => {
		return (
			<div>
				{data.body.map((paragraph) => {
					return <p>{paragraph}</p>;
				})}
			</div>
		);
	};

	render() {
		return (
			<div>
				<Header />
				<div className='row'>
					<div className='topTitle'>
						<h1>{data.title}</h1>
					</div>
					<div className='double-column'>
						<div className='column'>
							<div className='section-main'>
								<div className='section-main-title'>
									<img src={heroImage}></img>
									<div style={{ minWidth: '300px' }}></div>
								</div>

								<div className='section-main-description'>
									<p>{this.renderBody()}</p>
									<ul style={{ listStyleType: 'none', padding: '0' }}>
										<li>American Rivers</li>
										<ul>
											<li>
												American Rivers protects and restores rivers to benefit
												people and wildlife while ensuring clean drinking water,
												natural flood protection and opportunities for
												recreation.
											</li>
										</ul>

										<li>Food & Water Watch</li>
										<ul>
											<li>
												Food & Water Watch mobilizes regular people to build
												political power for solutions to the most pressing food,
												water, and climate problems of our time.
											</li>
										</ul>

										<li>Oceana</li>
										<ul>
											<li>
												Oceana is the largest international advocacy
												organization dedicated solely to ocean conservation.
											</li>
										</ul>

										<li>Ocean Conservancy</li>
										<ul>
											<li>
												Ocean Conservancy informs, inspires and empowers
												citizens to take action on behalf of the ocean.
											</li>
										</ul>

										<li>Surfrider Foundation</li>
										<ul>
											<li>
												Surfrider Foundation is dedicated to the protection and
												enjoyment of the world's ocean, waves and beaches for
												all people, through a powerful activist network.
											</li>
										</ul>

										<li>Center for Environmental Law and Policy (CELP) </li>
										<ul>
											<li>
												CELP is a statewide organization that protects,
												preserves and restores Washington State's waters through
												education, policy reform, agency advocacy, and
												litigation.
											</li>
										</ul>

										<li>Oyster Recovery Partnership</li>
										<ul>
											<li>
												Oyster Recovery Partnership works to restore the
												Chesapeake Bay's native oyster population by
												constructing large-scale sanctuaries, replenishing
												public fishery reefs, supporting aquaculture, and
												recycling shells.
											</li>
										</ul>

										<li>Reef Relief </li>
										<ul>
											<li>
												Reef Relief is dedicated to improving and protecting our
												coral reef ecosystem.
											</li>
										</ul>
									</ul>
								</div>
							</div>
						</div>
					</div>

					<div className='column'>
						<div className='section-donate'>
							<Donate />
							<DonationOptions stripeAction={this.showModal} />
							<SocialComponent />
							<div class='section-donate-disclaimer'>
								*While we work to ensure accuracy, the stats indicated on this
								page may not be an exact reflection of actual activity.
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		);
	}
}

export default withRouter(App);
